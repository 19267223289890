import {useId, useState} from 'react';
import {Form, InputGroup} from 'react-bootstrap';

export function FilterLineInputLock({defaultIsLocked, defaultValue, field, updateValue, isDisabled}) {
    const id = useId();

    const [isLocked, setIsLocked] = useState('NUM' === field.type ? defaultIsLocked : false);
    const [value, setValue] = useState(field.value || defaultValue || 0); // 0 is NUM only

    const renderInput = () => {
        switch (field.type) {
            case 'CHOICE':
                return [...field.choices].sort().map((choice, key) =>
                    <Form.Check
                        checked={choice === value}
                        disabled={true === isDisabled}// || true === isLocked}
                        id={`${field.field}-${choice}-${id}`}
                        key={key}
                        label={choice}
                        onChange={() => switchOnChange(value !== choice ? choice : '')}
                        type="radio"
                    />
                );
            case 'FLAG':
                return (
                    // originally a three-state component, it has been downgraded to a classic for user xp reasons
                    <Form.Switch
                        checked={true === value}
                        disabled={true === isDisabled}// || true === isLocked}
                        label={<span onClick={() => switchOnChange(!value)}>{field.name}</span>}
                        onChange={event => switchOnChange(event.currentTarget.checked)}
                    />
                );

            case 'NUM':
                return (
                    <InputGroup className="mb-2" size="sm">
                        <Form.Label className={`mb-0` + (true === isLocked ? ` text-body-tertiary` : ``)} size="sm">
                            {true === isDisabled || true === isLocked
                                ?
                                field.name
                                :
                                <>
                                    {field.name}{'' !== value && <span className="fw-bold ps-1">≥ {value}</span>}
                                    <a className="ms-2" onClick={reset} role="button">clear</a>
                                </>
                            }
                        </Form.Label>
                        <Form.Range
                            disabled={true === isDisabled || true === isLocked}
                            max={field.max}
                            min={field.min}
                            onChange={event => {
                                const newValue = event.currentTarget.value;
                                setValue(newValue);
                                updateValue(newValue);
                            }}
                            step={field.step}
                            value={value}
                        />
                    </InputGroup>
                );
        }
    };

    const reset = event => {
        event.stopPropagation();
        setIsLocked(true);
        updateValue(''); // reset to empty, null is/was used for third state
        setValue(defaultValue);
    };

    const switchOnChange = localValue => {
        if (false === localValue) {
            localValue = '';
        }
        setValue(localValue);
        updateValue(localValue);
    };

    return (
        <span
            onClick={() => {
                // unlock
                if (true === isLocked) {
                    setIsLocked(false);
                    updateValue(value); // set to value which kept previous state
                }
            }}
        >
            {renderInput()}
        </span>
    );
}
