import {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {useMap} from 'react-leaflet/hooks';
import {Accordion, Button, Dropdown, DropdownButton, Form} from 'react-bootstrap';
import {
    ArrowBarUp as IconArrowBarUp,
    Eye as IconEye,
    EyeSlash as IconEyeSlash,
    Filter as IconFilter
} from 'react-bootstrap-icons';
import {FilterLineForm, FilterLineInputLock} from '.';
import {setFilters as setUserFilters} from '../redux/userSlice';
import {operatorService} from '../services';
import {
    copyDeep,
    DEFAULT_NON_ATLAS_FILTER,
    filter,
    getGeoJsonFromElements,
    isObject,
    isObjectEmpty,
    isObjectEqual
} from '../utils';

export function FilterLine({lastHighlightedKey, layer, layerRef, resetDataLayer, setLastHighlightedKey, updateDataLayer}) {
    const dispatch = useDispatch();
    const map = useMap();
    const defaultFormValues = { // store default values to allow for reset
        0: layer.defaults,
    };

    const userFilters = useSelector(state => state.user.value.filters); // state filters, update whenever user makes a change to the filter (atlas layers) or whenever submitted (non-atlas layers)

    const [filters, setFilters] = useState({}); // interface filters and their data, for atlas layers only
    const [filterKey, setFilterKey] = useState(0); // key of a list of form values
    const [formValues, setFormValues] = useState(defaultFormValues); // for non-atlas layers only
    const [isLoading, setIsLoading] = useState(false);
    const [show, setShow] = useState(true === layer.isFilterOpen);

    useEffect(() => {
        if (false === userFilters?.[layer._id]?.isAtlas) {
            setFormValues(userFilters[layer._id].formValues);
        }

        return () => {
            setFormValues(defaultFormValues);
        }
    }, [userFilters]);

    useEffect(() => {
        // init filters based on layer's search_fields and user filters
        if (0 < layer.search_fields.length) {
            // init interface filters for that layer
            const localFilters = {
                'equals': [],
                'phrase': [],
                'range': [],
            };
            // keys that require choices to be retrieved from elements
            const searchChoicesKeys = [];
            layer.search_fields.forEach(searchField => {
                let defaultValue;
                switch (searchField.type) {
                    case 'CHOICE': // radio input
                        defaultValue = getDefaultValue(searchField.field, userFilters[layer._id]?.equals);
                        // add search field to filter
                        localFilters.equals.push({
                            ...searchField,
                            'defaultValue': defaultValue,
                            'value': defaultValue,
                        });

                        // no choices provided, add key
                        if (true === Array.isArray(searchField.choices) && 0 === searchField.choices.length) {
                            searchChoicesKeys.push(searchField.field);
                        }

                        break;
                    case 'FLAG': // switch (checkbox) input
                        defaultValue = getDefaultValue(searchField.field, userFilters[layer._id]?.equals);
                        // add search field to filter
                        localFilters.equals.push({
                            ...searchField,
                            'defaultValue': defaultValue,
                            'value': defaultValue,
                        });
                        break;
                    case 'NUM': // range (slider) input
                        defaultValue = getDefaultValue(searchField.field, userFilters[layer._id]?.range);
                        // add search field to filter
                        localFilters.range.push({
                            ...searchField,
                            'defaultValue': defaultValue,
                            'value': defaultValue,
                        });
                        break;
                }
            });

            if (0 < searchChoicesKeys.length) {
                // for all elements
                layer.data.forEach(element => {
                    // for all required choices, determine the element's value matching the key
                    searchChoicesKeys.forEach(searchChoicesKey => {
                        const choicesKeys = searchChoicesKey.split('.');
                        const firstLevelKey = choicesKeys[0];
                        const secondLevelKey = choicesKeys[1];
                        let choicesValue = null;
                        let firstLevelProperties = element.properties?.[firstLevelKey];

                        // we matched, exit loop
                        if (undefined === firstLevelProperties) {
                            return;
                        }

                        // attempt matching a nested value (either in an array or an object), e.g. code from "substations.code"
                        if (true === Array.isArray(firstLevelProperties)) {
                            for (const firstLevelProperty of firstLevelProperties) {
                                if (true === secondLevelKey in firstLevelProperty) {
                                    choicesValue = firstLevelProperty[secondLevelKey];
                                    // we have a match, exit loop
                                    break;
                                }
                            }
                        } else if (true === isObject(firstLevelProperties) && true === secondLevelKey in firstLevelProperties) {
                            choicesValue = firstLevelProperties[secondLevelKey];
                        }

                        // skip if there's yet another level
                        if (true === Array.isArray(choicesValue) || true === isObject(choicesValue)) {
                            return;
                        }

                        // store the matched value on choices (only works for search fields of type 'equals'), if new
                        for (const [searchFieldKey, searchField] of Object.entries(localFilters.equals)) {
                            if (searchField.field === searchChoicesKey &&
                                false === searchField.choices.includes(choicesValue)) {
                                localFilters.equals[searchFieldKey].choices = [
                                    ...searchField.choices,
                                    choicesValue,
                                ];
                                // we matched, exit loop
                                break;
                            }
                        }
                    });
                });

                setFilters(localFilters);
            }
        } else {
            setShow(true === layer.isFilterOpen);
        }

        return () => {
            setFilters({});
        };
    }, [layer]);

    // disable filter button when one of the form values is invalid
    useEffect(() => {
        if (false === isObjectEqual(formValues, defaultFormValues)) {
            let isValid = false;

            // invalid if empty
            if (JSON.stringify(DEFAULT_NON_ATLAS_FILTER) !== JSON.stringify(formValues)) {
                isValid = isFormValuesValid(formValues);
            }

            setIsLoading(false === isValid);
        }

        return () => {
            setIsLoading(false);
        };
    }, [formValues]);

    const _updateUserFilter = (layerId, localFormValues) => {
        const localUserFilters = copyDeep(userFilters);
        localUserFilters[layerId].formValues = localFormValues;
        dispatch(setUserFilters(localUserFilters));
    };

    // persist to user filters and filter through backend
    const formSubmit = localUserFilter => {
        setIsLoading(true);

        const fields = {
            'equals': [],
            'phrase': [],
            'range': [],
        };
        const fieldsEmbeddedDocument = {
            'path': 'substations',
            'fields': copyDeep(fields),
        };

        localUserFilter.equals.map(filter => {
            if (null !== filter.value) {
                const keyValue = {
                    'key': filter.key,
                    'value': filter.value,
                };
                if (true === filter.embeddedDocument) {
                    fieldsEmbeddedDocument.fields.equals.push(keyValue);
                } else {
                    fields.equals.push(keyValue);
                }
            }
        });

        localUserFilter.phrase.map(filter => {
            if (null !== filter.value) {
                const keyValue = {
                    'key': filter.key,
                    'value': filter.value,
                };
                if (true === filter.embeddedDocument) {
                    fieldsEmbeddedDocument.fields.phrase.push(keyValue);
                } else {
                    fields.phrase.push(keyValue);
                }
            }
        });

        localUserFilter.range.map(filter => {
            if (null !== filter.value) {
                const keyValue = {
                    'key': filter.key,
                    'value': filter.value,
                };
                if (true === filter.embeddedDocument) {
                    fieldsEmbeddedDocument.fields.range.push(keyValue);
                } else {
                    fields.range.push(keyValue);
                }
            }
        });

        operatorService.getElements(layer._id, fields, localUserFilter.geometry, fieldsEmbeddedDocument)
            .then(elements => {
                updateDataLayer({
                    'layer': {
                        ...layer,
                        'data': getGeoJsonFromElements(elements),
                    },
                    'layerRef': layerRef,
                });
            })
            .finally(() => {
                setIsLoading(false);
            });
    };

    // filter on frontend
    const formSubmitLegacy = () => {
        setIsLoading(true);

        _updateUserFilter(layer._id, formValues);

        updateDataLayer({
            'layer': {
                ...layer,
                'data': filter(formValues, layer.data),
            },
            'layerRef': layerRef,
        });

        setIsLoading(false);
    };

    // combine first and second level keys of all elements for exhaustive filtering
    const getAvailableKeys = () => {
        let availableKeys = {};
        layer.data.forEach(element => {
            if ('properties' in element) {
                Object.keys(element.properties).forEach(keyFirstLevel => {
                    if (false === keyFirstLevel in availableKeys) {
                        availableKeys[keyFirstLevel] = [];
                    }
                    if (null !== element.properties[keyFirstLevel] && true === isObject(element.properties[keyFirstLevel])) {
                        Object.keys(element.properties[keyFirstLevel]).forEach(keySecondLevel => {
                            if (false === availableKeys[keyFirstLevel].includes(keySecondLevel)) {
                                availableKeys[keyFirstLevel].push(keySecondLevel);
                            }
                        });
                    }
                });
            }
        });

        return availableKeys;
    };

    const getDefaultValue = (field, userFilterFields) => {
        let defaultValue = ''; // null is already used in FilterLineInputLock as third state
        if (true === Array.isArray(userFilterFields)) {
            for (const userFilterField of userFilterFields) {
                if (field === userFilterField.key) {
                    defaultValue = userFilterField.value;
                    break;
                }
            }
        }

        return defaultValue;
    };

    const isFilterValid = values => {
        return '' !== values.condition && '' !== values.key && '' !== values.value;
    };

    const isFormValuesValid = localFormValues => {
        let isValid = true;
        Object.keys(localFormValues).every(key => {
            if (false === isFilterValid(localFormValues[key])) {
                isValid = false;
                return false;
            }

            return true;
        });

        return isValid;
    };

    const renderFilters = () => {
        if (false === isObjectEmpty(filters)) {
            return (
                <Accordion defaultActiveKey={[]} flush>
                    {renderFieldsFormGroup('Flags', 'FLAG')}
                    {renderFieldsFormGroup('Nums', 'NUM')}
                    {filters.equals.filter(field => 'CHOICE' === field.type).map(fieldChoice => renderFieldsFormGroup(fieldChoice.name, 'CHOICE', fieldChoice.field/*, 'Substations' === fieldChoice.name && 'Substation' === userSelection?.type*/))}
                </Accordion>
            );
        }

        const availableKeys = getAvailableKeys();
        return (
            Object.keys(formValues).map(formValueKey =>
                <FilterLineForm
                    availableKeys={availableKeys}
                    defaults={formValues[formValueKey]}
                    handleUpdate={values => {
                        setFormValues({
                            ...formValues,
                            [formValueKey]: values,
                        });
                    }}
                    key={formValueKey}
                    submit={() => {}} // do nothing (for now)
                />
            )
        );
    }

    const renderForm = () => {
        if (false === show) {
            return;
        }

        // backend (Atlas-powered) filtering
        if (true === ['highVoltageLine', 'plot'].includes(layer.type)) {
            return (
                <Form className="mt-1 field-filters">
                    {renderFilters()}
                    <div className="container g-0 mt-1 text-center">
                        <div className="g-2 row">
                            <div className="col-6">
                                <div className="d-grid">
                                    <Button disabled={true === isLoading} onClick={() => {
                                        // reset and persist user filters
                                        const localUserFilters = copyDeep(userFilters);
                                        localUserFilters[layer._id] = layer.defaultAtlasFilters;
                                        dispatch(setUserFilters(localUserFilters));

                                        // submit with default filters
                                        formSubmit(localUserFilters[layer._id]);

                                        // close the filter since the different fields don't reset otherwise
                                        setShow(false);
                                    }} variant="outline-secondary">Reset</Button>
                                </div>
                            </div>
                            <div className="col-6">
                                <div className="d-grid">
                                    <Button disabled={true === isLoading} onClick={() => formSubmit(userFilters[layer._id])}>
                                        Filter
                                    </Button>
                                </div>
                            </div>
                        </div>
                    </div>
                </Form>
            );
        }

        // other layers, client-side filtering
        const availableKeys = getAvailableKeys();
        return (
            <Form className="mt-3" onSubmit={event => event.preventDefault()}>
                <Form.Group>
                    {Object.keys(userFilters[layer._id].formValues).map(formValueKey =>
                        <FilterLineForm
                            layerName={layer.name}
                            availableKeys={availableKeys}
                            defaults={userFilters[layer._id].formValues[formValueKey]}
                            handleUpdate={values => {
                                setFormValues({
                                    ...userFilters[layer._id].formValues,
                                    [formValueKey]: values,
                                });
                            }}
                            key={formValueKey}
                            submit={formSubmitLegacy}
                        />
                    )}
                    <div className="container g-0 text-center">
                        <div className="g-2 row">
                            <div className="col-6">
                                <DropdownButton className="d-grid" title="More" variant="outline-secondary">
                                    <Dropdown.Item  className="d-grid" onClick={() => {
                                        const lastFormValuesKey = Object.keys(formValues).map(localKey => parseInt(localKey)).pop();
                                        const localFormValues = {
                                            ...userFilters[layer._id].formValues,
                                            [lastFormValuesKey+1]: {},
                                        };
                                        setFormValues(localFormValues);

                                        _updateUserFilter(layer._id, localFormValues);
                                    }}>Add filter</Dropdown.Item>
                                    {false === isObjectEmpty(formValues) && <Dropdown.Item onClick={() => {
                                        let localFormValues = copyDeep(formValues);
                                        const lastFormValuesKey = Object.keys(formValues).map(localKey => parseInt(localKey)).pop();
                                        delete localFormValues[lastFormValuesKey];
                                        setFormValues(localFormValues);

                                        _updateUserFilter(layer._id, localFormValues);
                                    }}>Remove filter</Dropdown.Item>}
                                    <Dropdown.Item onClick={() => {
                                        // remove additional filters, reset first
                                        const localFormValues = {
                                            ...defaultFormValues,
                                        };
                                        setFormValues(localFormValues);
                                        resetDataLayer();

                                        _updateUserFilter(layer._id, localFormValues);
                                    }}>Reset data</Dropdown.Item>
                                </DropdownButton>
                            </div>
                            <div className="col-6">
                                <div className="d-grid">
                                    <Button disabled={true === isLoading} onClick={formSubmitLegacy}>
                                        Filter
                                    </Button>
                                </div>
                            </div>
                        </div>
                    </div>
                </Form.Group>
            </Form>
        );
    };

    const renderFieldsFormGroup = (title, searchFieldType, fieldKey = null, isDisabled = false) => {
        const searchType = ['CHOICE', 'FLAG'].includes(searchFieldType) ? 'equals' : 'range';
        const localFilters = 'CHOICE' === searchFieldType ? filters[searchType].filter(field => field.field === fieldKey) : filters[searchType].filter(field => 'CHOICE' !== field.type);
        if (0 < localFilters.length) {
            // determine the number of active fields, CHOICE fields (radio buttons) are either 1 or 0
            const activeFilterCount = localFilters.filter(filter => {
                if ('CHOICE' === searchFieldType) {
                    return 0 < localFilters.filter(field => field.choices.includes(filter.value)).length;
                }

                return '' !== filter.value;
            }).length || 0;

            return (
                <Accordion.Item eventKey={title} key={title}>
                    <Accordion.Header>{title} {0 < activeFilterCount && <span className="badge ms-2 text-bg-light">{activeFilterCount}</span>}</Accordion.Header>
                    <Accordion.Body>
                        {localFilters.map((field, key) =>
                            <FilterLineInputLock
                                defaultIsLocked={'' === field.value}
                                defaultValue={userFilters[layer._id][searchType].filter(filter => filter.key === field.field).map(filter => filter.value).pop()}
                                field={field}
                                isDisabled={true === isDisabled}
                                key={key}
                                updateValue={newValue => {
                                    // update user filters
                                    const localUserFilters = copyDeep(userFilters);
                                    // remove this field from user filters
                                    localUserFilters[layer._id][searchType] = localUserFilters[layer._id][searchType].filter(filter => filter.key !== field.field);
                                    // add back this field to user filters
                                    if ('' !== newValue) {
                                        localUserFilters[layer._id][searchType].push({
                                            'embeddedDocument': field.embeddedDocument,
                                            'key': field.field,
                                            'value': newValue,
                                        });
                                    }
                                    // persist
                                    dispatch(setUserFilters(localUserFilters));

                                    // update filters (to reflect change on active filter count)
                                    const localFilters = copyDeep(filters);
                                    localFilters[searchType] = localFilters[searchType].map(filter => {
                                        if (filter.field === field.field) {
                                            return {
                                                ...filter,
                                                'value': newValue,
                                            };
                                        }

                                        return filter;
                                    });
                                    setFilters(localFilters);
                                }}
                            />
                        )}
                        {/* @TODO: this resets the user filter for the given fieldKey but doesn't unset the radio buttons */}
                        {/*{'phrase' === searchType && <a className="mt-2" onClick={() => {*/}
                        {/*    const localUserFilters = copyDeep(userFilters);*/}
                        {/*    // remove this field from user filters*/}
                        {/*    localUserFilters[layer._id][searchType] = localUserFilters[layer._id][searchType].filter(filter => filter.key !== fieldKey);*/}
                        {/*    // persist*/}
                        {/*    dispatch(setUserFilters(localUserFilters));*/}
                        {/*}} role="button">clear</a>}*/}
                    </Accordion.Body>
                </Accordion.Item>
            );
        }
    };

    if (null !== layerRef) {
        const localLastHighlightedKey = `${layer._id}-${layer.order}`;
        return (
            <li className={`list-group-item${localLastHighlightedKey === lastHighlightedKey ? ' highlight' : ''}`} key={`${layer._id}-${filterKey}`}>
                <div className="d-flex justify-content-between align-items-center">
                    <span>
                        {layer.name} <span className="badge bg-secondary ms-2">{layer.data.length}</span>
                    </span>
                    <span>
                        {true === map.hasLayer(layerRef) ?
                            <IconEye onClick={() => {
                                map.removeLayer(layerRef);
                                setFilterKey(filterKey + 1); // force render
                            }} role="button" size={20} title="Hide"/>
                            :
                            <IconEyeSlash onClick={() => {
                                setFilterKey(filterKey + 1); // force render
                                setLastHighlightedKey(localLastHighlightedKey);
                                updateDataLayer({
                                    'layer': layer,
                                    'layerRef': layerRef,
                                });
                            }} role="button" size={20} title="Show"/>
                        }
                        <IconFilter className="me-2 ms-2" onClick={() => {
                            setShow(!show);
                        }} role="button" size={20} title="Toggle filter"/>
                        <IconArrowBarUp onClick={() => {
                            setLastHighlightedKey(localLastHighlightedKey);
                            updateDataLayer({
                                'layer': layer,
                                'layerRef': layerRef,
                            }, true);
                        }} role="button" size={20} title="Bring to front"/>
                    </span>
                </div>
                {renderForm()}
            </li>
        );
    }
}
