import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import L from 'leaflet';
import Form from 'react-bootstrap/Form';
import debounce from 'debounce';
import {
    Arrow90degRight as IconArrowProject,
    ArrowRepeat as IconArrowRepeat,
    Filter as IconFilter,
    Person as IconPerson,
} from 'react-bootstrap-icons';
import {
    setSelection as setUserSelection,
    setSubstation as setUserSubstation,
} from '../redux/userSlice';
import {operatorService} from '../services';
import {getCoordinatesForGeometry, getLatLng} from '../utils';

export function Search({
                           filtersShow,
                           isLoading,
                           profileShow,
                           project,
                           projectShow,
                           searchInput,
                           setFiltersShow,
                           setIsLoading,
                           setProfileShow,
                           setProject,
                           setProjectShow,
                           setSearchInput,
                       }) {
    const dispatch = useDispatch();

    const layersTypes = useSelector(state => state.layers.value.types);
    const userSelection = useSelector(state => state.user.value.selection);
    const userSubstation = useSelector(state => state.user.value.substation);

    const [searchResultsCoordinates, setSearchResultsCoordinates] = useState(null);
    const [searchResultsInstallation, setSearchResultsInstallation] = useState([]);
    const [searchResultsMunicipality, setSearchResultsMunicipality] = useState([]);
    const [searchResultsPlot, setSearchResultsPlot] = useState([]);
    const [searchResultsProject, setSearchResultsProject] = useState([]);
    const [searchResultsSubstation, setSearchResultsSubstation] = useState([]);
    const [suggestionsShow, setSuggestionsShow] = useState(false);

    useEffect(() => {
        // stop event propagation to map for interactions with the search component
        const div = document.getElementById('search');
        L.DomEvent.disableClickPropagation(div);

        // close suggestions on escape
        const handleKeyDown = (event) => {
            if (event.key === 'Escape') {
                setFiltersShow(false);
                setSuggestionsShow(false);
            }
        };
        window.addEventListener('keydown', handleKeyDown);

        return () => {
            setSuggestionsShow(false);
            window.removeEventListener('keydown', handleKeyDown);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handleSearch = (event) => {
        let input = event.target.value;

        // update search input value
        setSearchInput(input);
        // close list of suggestions
        setSuggestionsShow(false);

        // ignore if too short
        if (2 > input.length) {
            return;
        }

        input = input.trim();

        // check whether input is a GPS coordinate
        setSearchResultsCoordinates(null);
        const coordinates = getLatLng(input);
        if (null !== coordinates) {
            // show entered coordinates as a clickable result
            setSearchResultsCoordinates({
                '_id': input,
                'data': {
                    'latitude': coordinates[0],
                    'longitude': coordinates[1],
                },
                'display_name': input,
                'search_value': input,
                'type': 'Coordinates',
            });

            setSuggestionsShow(true);
            setFiltersShow(false);

            // show nearby substations, if any
            undefined !== layersTypes.substation && operatorService.near(layersTypes.substation._id, coordinates)
                .then(items => {
                    setSearchResultsSubstation(
                        items.map(item => ({
                            '_id': item._id,
                            'code': item.metadata.id_PS,
                            'display_name': item.display_name,
                            'geometry': item.geometry,
                            'metadata': item.metadata,
                            'search_value': item.display_name,
                            'type': 'Substation',
                        }))
                    );
                });
        } else {
            debounce(() => {
                // search by substation
                setSearchResultsSubstation([]);
                undefined !== layersTypes.substation && operatorService
                    .autocomplete(layersTypes.substation._id, ['display_name', 'metadata.Nom PS'], input, 5)
                    .then(items => {
                        setSearchResultsSubstation(
                            items.map(item => {
                                let searchValue = item.display_name;
                                if (true === 'Nom PS' in item.metadata) {
                                    searchValue = `${item.metadata['Nom PS']} ${searchValue}`;
                                }
                                return {
                                    '_id': item._id,
                                    'display_name': item.display_name,
                                    'geometry': item.geometry,
                                    'metadata': item.metadata,
                                    'search_value': searchValue,
                                    'type': 'Substation',
                                };
                            })
                        );

                        if (0 < items.length) {
                            setSuggestionsShow(true);
                        }
                    })
                    .finally(() => {
                        setFiltersShow(false);
                    });
                // search by municipalities
                setSearchResultsMunicipality([]);
                undefined !== layersTypes.municipality && operatorService
                    .autocomplete(
                        layersTypes.municipality._id,
                        ['display_name', 'metadata.code', 'metadata.codesPostaux'],
                        input,
                        5
                    )
                    .then(items => {
                        const results = items.map(item => {
                            let codeInsee = '';
                            if (undefined !== item.metadata.code) {
                                codeInsee = ` ${item.metadata.code}`;
                            }

                            return {
                                '_id': item._id,
                                'data': {
                                    'coordinates': getCoordinatesForGeometry(item.geometry),
                                },
                                'display_name': item.display_name,
                                'geometry': item.geometry,
                                'search_value': `${item.display_name}${codeInsee}`,
                                'type': 'Municipality',
                            };
                        });
                        setSearchResultsMunicipality(results);

                        if (0 < items.length) {
                            setSuggestionsShow(true);
                        }
                    })
                    .finally(() => {
                        setFiltersShow(false);
                    });
                // search by plot ID
                setSearchResultsPlot([]);
                undefined !== layersTypes.plot && operatorService
                    .autocomplete(layersTypes.plot._id,['display_name'], input, 5)
                    .then(items => {
                        setSearchResultsPlot(
                            items.map(item => ({
                                '_id': item._id,
                                'display_name': item.display_name,
                                'geometry': item.geometry,
                                'metadata': item.metadata,
                                'search_value': item.display_name,
                                'type': 'Plot',
                            }))
                        );

                        if (0 < items.length) {
                            setSuggestionsShow(true);
                        }
                    })
                    .finally(() => {
                        setFiltersShow(false);
                    });
                // search by project
                setSearchResultsProject([]);
                undefined !== layersTypes.project && operatorService
                    .autocomplete(layersTypes.project._id, ['display_name'], input, 5)
                    .then(items => {
                        setSearchResultsProject(
                            items.map(item => ({
                                '_id': item._id,
                                'data': {
                                    'geometry': item.geometry,
                                },
                                'display_name': item.display_name,
                                'metadata': item.metadata,
                                'search_value': item.display_name,
                                'type': 'Project',
                            }))
                        );

                        if (0 < items.length) {
                            setSuggestionsShow(true);
                        }
                    })
                    .finally(() => {
                        setFiltersShow(false);
                    });
                // search by JBox/IECharge
                setSearchResultsInstallation([]);
                undefined !== layersTypes.installation && operatorService
                    .autocomplete(layersTypes.installation._id, ['display_name'], input, 5)
                    .then(items => {
                        setSearchResultsInstallation(
                            items.map(item => ({
                                '_id': item._id,
                                'data': {
                                    'geometry': item.geometry,
                                },
                                'display_name': item.display_name,
                                'metadata': item.metadata,
                                'search_value': item.display_name,
                                'type': 'Installation',
                            }))
                        );

                        if (0 < items.length) {
                            setSuggestionsShow(true);
                        }
                    })
                    .finally(() => {
                        setFiltersShow(false);
                    });
            }, 300)();
        }
    };

    const selectResult = newSelection => {
        setIsLoading(true);
        setSearchInput(newSelection.search_value);
        setSuggestionsShow(false);
        dispatch(setUserSelection(newSelection));
        dispatch(setUserSubstation('Substation' === newSelection.type ? newSelection : null));
    };

    const toggleFiltersShow = () => {
        setFiltersShow(!filtersShow);
        setProfileShow(false);
        setSuggestionsShow(false);
    };

    const toggleProfileShow = () => {
        setProfileShow(!profileShow);
        setFiltersShow(false);
        setSuggestionsShow(false);
    };

    const toggleProjectShow = () => {
        const isProjectShow = !projectShow;
        if (true === isProjectShow) {
            // set substation to fresh projects or those that don't have one
            const localSubstationCode = userSubstation?.display_name;
            if (null === project) {
                setProject({
                    'plot_ids': [],
                    'substation_code': localSubstationCode,
                });
            } else {
                setProject({
                    ...project,
                    'substation_code': localSubstationCode,
                });
            }
        }
        setProjectShow(isProjectShow);
    };

    return (
        <div id="search">
            <div className="input-group mb-1">
                <Form.Control
                    className="form-control-lg"
                    onChange={handleSearch}
                    type="text"
                    value={searchInput}
                />
                {null !== userSelection && (
                    <span
                        className={`input-group-text p-1`}
                        onClick={() => {
                            dispatch(
                                setUserSelection({
                                    ...userSelection,
                                })
                            );
                        }}
                        title="Refresh"
                    ><IconArrowRepeat size={24}/></span>
                )}
                <span
                    className={`input-group-text p-1${filtersShow ? ' active' : ''}`}
                    onClick={toggleFiltersShow}
                    title="Toggle filter view"
                ><IconFilter size={24}/></span>
                <span
                    className={`input-group-text p-1${projectShow ? ' active' : ''}`}
                    onClick={toggleProjectShow}
                    title="Toggle project view"
                ><IconArrowProject size={24}/></span>
                <span
                    className={`input-group-text p-1${profileShow ? ' active' : ''}`}
                    onClick={toggleProfileShow}
                    title="Toggle profile view"
                ><IconPerson size={24}/></span>
            </div>
            {true === isLoading && (
                <div className="search-information">
                    <ul>
                        <li className="fst-italic no-results p-2">Loading...</li>
                    </ul>
                </div>
            )}
            {true === suggestionsShow && (
                <div className="search-information">
                    {null === searchResultsCoordinates &&
                        0 === searchResultsSubstation.length &&
                        0 === searchResultsMunicipality.length &&
                        0 === searchResultsPlot.length &&
                        0 === searchResultsProject.length && (
                            <ul>
                                <li className="fst-italic no-results p-2">No results</li>
                            </ul>
                        )}
                    {null !== searchResultsCoordinates && (
                        <>
                            <div className="p-2 pb-0 fst-italic">Coordinates</div>
                            <ul>
                                <li onClick={() => selectResult(searchResultsCoordinates)}>
                                    Latitude: {searchResultsCoordinates.data.latitude}, Longitude:{' '}
                                    {searchResultsCoordinates.data.longitude}
                                </li>
                            </ul>
                        </>
                    )}
                    {0 < searchResultsSubstation.length && (
                        <>
                            <div className="p-2 pb-0 fst-italic">Substations</div>
                            <ul>
                                {searchResultsSubstation.map((result, key) => (
                                    <li key={key} onClick={() => selectResult(result)}>
                                        {result.search_value.toUpperCase()}
                                    </li>
                                ))}
                            </ul>
                        </>
                    )}
                    {0 < searchResultsMunicipality.length && (
                        <>
                            <div className="p-2 pb-0 fst-italic">Municipalities</div>
                            <ul>
                                {searchResultsMunicipality.map((result, key) => (
                                    <li key={key} onClick={() => selectResult(result)}>
                                        {result.search_value.toUpperCase()}
                                    </li>
                                ))}
                            </ul>
                        </>
                    )}
                    {0 < searchResultsPlot.length && (
                        <>
                            <div className="p-2 pb-0 fst-italic">Plots</div>
                            <ul>
                                {searchResultsPlot.map((result, key) => (
                                    <li key={key} onClick={() => selectResult(result)}>
                                        {result.search_value.toUpperCase()}
                                    </li>
                                ))}
                            </ul>
                        </>
                    )}
                    {0 < searchResultsProject.length && (
                        <>
                            <div className="p-2 pb-0 fst-italic">Projects</div>
                            <ul>
                                {searchResultsProject.map((result, key) => (
                                    <li key={key} onClick={() => selectResult(result)}>
                                        {result.search_value.toUpperCase()}
                                    </li>
                                ))}
                            </ul>
                        </>
                    )}
                    {0 < searchResultsInstallation.length && (
                        <>
                            <div className="p-2 pb-0 fst-italic">JBox & IECharge</div>
                            <ul>
                                {searchResultsInstallation.map((result, key) => (
                                    <li key={key} onClick={() => selectResult(result)}>
                                        {result.search_value.toUpperCase()}
                                    </li>
                                ))}
                            </ul>
                        </>
                    )}
                </div>
            )}
        </div>
    );
}
