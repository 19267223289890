export const MODE_DEFAULT = 'DEFAULT';
export const MODE_KEY_ACCOUNTS = 'GRAND_COMPTE';
export const MODE_PROSPECTION = 'PROSPECTION';

export const availableModes = {
    [MODE_DEFAULT]: {
        // order is important and will be reflected on the app
        'activeLayers': {
            'FI': [
            ],
            'FR': [
                'Plots',
                'HTA Lines',
                'Municipalities',
                'Substations v2',
            ],
            'SE': [
                'Substations',
            ],
            'TX': [
                'Substations',
                'Substations Zones',
            ],
        },
        'label': 'Default',
    },
    [MODE_KEY_ACCOUNTS]: {
        // order is important and will be reflected on the app
        'activeLayers': {
            'FR': [
                'Plots',
                'HTA Lines',
                'Municipalities',
                'Substations v2',
            ],
        },
        'label': 'Key Accounts',
    },
    [MODE_PROSPECTION]: {
        // order is important and will be reflected on the app
        'activeLayers': {
            'FI': [
                'Substations',
                'Plots',
                'Municipalities',
            ],
            'FR': [
                'Substations v2', // general
                'JBox and IECharge',
                'Project Plots',
                'HTA Lines',
                'Plots',
                'Municipalities',
            ],
        },
        'label': 'Prospection',
    },
};
