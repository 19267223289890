const LOCAL_STORAGE_KEY = 'jsp_user_profile_v2024-10-15';

// read from local storage or init
export function getUserProfile() {
    const userProfile = JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEY));
    // read from local storage
    if (userProfile !== null) {
        return userProfile;
    }

    // init
    return {
        'country': null,
        'layers': {},
        'modes': {},
    };
}

// write to local storage
export function setUserProfile(profile) {
    localStorage.setItem(LOCAL_STORAGE_KEY, JSON.stringify(profile));
}
